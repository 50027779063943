export default [
  {
    header: 'La tua azienda',
    action: 'read',
    resource: 'ACL',
  },
  {
    title: 'Anagrafica',
    route: 'azienda-anagrafica',
    icon: 'UserIcon',
    action: 'read',
    resource: 'ACL',
  },  
  {
    title: 'Richieste',
    icon: 'FileTextIcon',
    action: 'read',
    resource: 'ACL',
    children: [
      {
        title: 'In attesa',
        route: 'azienda-leads-in-attesa',
        action: 'read',
        resource: 'ACL',
      },
      {
        title: 'In corso',
        route: 'azienda-leads',
        action: 'read',
        resource: 'ACL',
      },
      {
        title: 'Concluse',
        route: 'azienda-leads-conslusi',
        action: 'read',
        resource: 'ACL',
      },
      {
        title: 'Nuova Richiesta',
        route: 'azienda-leads-nuovo',
        action: 'read',
        resource: 'ACL',
      },
    ],
  },
  {
    title: 'Ordini',
    route: 'azienda-ordini',
    icon: 'BoxIcon',
    action: 'read',
    resource: 'ACL',
  },
  
]
