<template>
  <b-nav-item :to="{ name: 'catalogo-carrello'}">
    <feather-icon
      size="21"
      icon="ShoppingCartIcon"
      :badge="$store.state['app-ecommerce'].cartItemsCount"
    />
  </b-nav-item>
</template>

<script>
import { BNavItem, BBadge } from 'bootstrap-vue'

export default {
  components: {
    BNavItem,
    BBadge,
  }
}
</script>
