/* eslint-disable */
// prettier-ignore
export default {
  pages: {
    key: 'title',
    data: [
      { title: 'Articoli', route: { name: 'catalogo-articoli' }, icon: 'ShoppingCartIcon', isBookmarked: true },
      { title: 'Anagrafica', route: { name: 'azienda-anagrafica' }, icon: 'UserIcon', isBookmarked: true },
      { title: 'Richieste in corso', route: { name: 'azienda-leads' }, icon: 'FileTextIcon', isBookmarked: false },
      { title: 'Ordini', route: { name: 'azienda-ordini' }, icon: 'BoxIcon', isBookmarked: true },
      
    ],
  },
  files: {
    key: 'file_name',
    data: [
      {
        file_name: "File prova 1",
        from: 'Mario Rossi',
        icon: require('@/assets/images/icons/doc.png'),
        size: '1.7 mb',
      },
      {
        file_name: 'Immagine demo',
        from: 'mario Bianchi',
        icon: require('@/assets/images/icons/jpg.png'),
        size: ' 52 kb',
      },
      {
        file_name: 'Word demo',
        from: 'Luighi Verde',
        icon: require('@/assets/images/icons/doc.png'),
        size: '1.5 mb',
      },
      {
        file_name: 'Altro documento word',
        from: 'Andrea Rosa',
        icon: require('@/assets/images/icons/doc.png'),
        size: '2.3 mb',
      },
      {
        file_name: 'dati di prova',
        from: 'Martina Viola',
        icon: require('@/assets/images/icons/doc.png'),
        size: ' 30 kb',
      }
    ],
  },
  contacts: {
    key: 'name',
    data: [
      {
        img: require('@/assets/images/portrait/small/avatar-s-4.jpg'),
        name: 'Gigi Alberti',
        email: 'email@dominioprova.it',
        time: '21/10/2021',
      },
      {
        img: require('@/assets/images/portrait/small/avatar-s-2.jpg'),
        name: 'Pier Paolo Rossi',
        email: 'pp.rossi@dominioaltro.com',
        time: '25/10/2021',
      },
      {
        img: require('@/assets/images/portrait/small/avatar-s-24.jpg'),
        name: 'Antonio Marrone',
        email: 'marrone@prova-dominio.it',
        time: '28/10/2021',
      },
    ],
  },
}
/* eslint-enable */
