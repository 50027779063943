<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT © {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        href="https://www.rubisco.it/"
        target="_blank"
      >Rubisco srls</b-link>
      <span class="d-none d-sm-inline-block">, All rights Reserved</span> | {{ appVersion }}
    </span>

    <span class="float-md-right d-none d-md-block">Sviluppato con <feather-icon
        size="8"
        icon="HeartIcon"
        class="text-primary ml-0"
      /> da <b-link
        class="ml-25"
        href="https://www.rubisco.it/"
        target="_blank"
      >Rubisco.it</b-link>
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BLink,
  },
  setup() {
    // versione app
    const { appVersion } = $themeConfig.app
    return {
      appVersion,
    }
  },
}
</script>
