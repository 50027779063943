export default [
  {
    header: 'Catalogo prodotti',
    action: 'read',
    resource: 'ACL',
  },
  {
    title: 'I tuoi Prodotti',
    route: 'catalogo-articoli',
    icon: 'ShoppingCartIcon',
    action: 'read',
    resource: 'ACL',
  },
  {
    title: 'Tutti i Prodotti',
    route: 'catalogo-articoli-tutti',
    icon: 'BookOpenIcon',
    /* tag: 'novità', */
    tagVariant: 'light-success',
    action: 'read',
    resource: 'ACL',
  },
  {
    title: 'Contatti',
    href: 'https://www.sistemianticaduta88.com/contact/',
    target: '_blank',
    icon: 'PhoneCallIcon',
    action: 'read',
    resource: 'ACL',
  },
  
]
